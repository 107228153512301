<template>
  <div>
    <h2>
      <span class="has-text-weight-bold">{{title}}</span> &nbsp;
      <a href="#" @click.prevent="handleAdd">Agregar</a>
    </h2>
    <ul class="lista" >
      <li v-for="(row,k) in rows" :key="`${k}`" class="is-flex is-justify-content-space-between	">
        {{row.newLabel}} 
        <div class="is-inline">
          <span 
            :title="`Editar ${row.label}`"
            @click.prevent="handleEdit(row)" 
            class="is-clickable has-text-warning">
            <b-icon icon="edit" />
          </span>
          <span 
            :title="`Eliminar ${row.label}`"
            @click.prevent="handleDelete(row)" 
            class="is-clickable has-text-danger">
            <b-icon icon="trash" />
          </span>
        </div>
      </li>
    </ul>
    <ModalForm 
      v-model="form"
      :show="showModal"
      @modal="showModal = !showModal"
      @cancel="handleClose" />
  </div>
</template>
<script>
import ModalForm from "./ModalVariableFormula.vue"
export default {
  components: {
    ModalForm
  },
  props:{
    title: {
      type: String,
      default: () => "",
    },
    type: {
      type: String,
      default: () => "v",
    },
    rows: {
      type: Array,
      default: () => [],
    }
  },
  data(){
    return {
      form: {},
      showModal: false,
    }
  },
  methods:{
    handleClose(){
      if(this.form.newLabel){
        this.$store.dispatch("entitie/saveCamposDinamicosRequest", this.form)
      }
    },
    handleAdd(){
      const f = {
        id: `id_${(new Date()).getTime()}`,
        type: "custom",
        label: "",
        newLabel: "",
      }
      if(this.type === "v") {
        f.labelTable = "";
        f.table = "venta";
        f.aggregateFunc = "sum";
        f.distinct = false;
        f.where = [];
      }
      else {
        f.formula = []
      }
      this.$set(this, "form", f)
      this.showModal = true
    },
    handleEdit(row){
      this.form = {...row}
      this.showModal = true
    },
    handleDelete(row){
      if(confirm(`A punto de eliminar "${row.label}". Desea continuar?`)){
        this.$store.dispatch("entitie/deleteCamposDinamicosRequest", row.id)
      }
    },
  }
}
</script>
<style scoped>
.lista{
  height: 400px;
  overflow-y: scroll;
  border: 1px solid;
  padding: 10px;
}
</style>