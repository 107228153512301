<template>
  <modal-base 
    v-model="vShow" 
    title="Opciones donde"
    @after-enter="onMain"
    @cancel="$emit(`cancel`)">
    <form-observer ref="observer2" id="form" @submit.prevent="handleSubmitFW">
      <input-base
        rules="required"
        label="Campo"
        label-horizontal
        expanded
        v-model="form.label"
        size="is-small"
      />
      <select-base
        rules="required"
        label="Operador"
        label-horizontal
        expanded
        :options="operadores"
        v-model="form.operator"
        size="is-small"
      />
      <input-base
        rules="required"
        label="Valor"
        label-horizontal
        expanded
        v-model="form.value"
        size="is-small"
      />
      <select-base
        rules=""
        label="Formato"
        label-horizontal
        expanded
        :options="opcionesDate2"
        v-model="form.format"
        size="is-small"
      />
      <input-base
        rules="numeric"
        label="Restar?"
        label-horizontal
        expanded
        v-model="form.subtract_number"
        size="is-small"
        type="number"
      />
    </form-observer>
    <template #footer>
      <b-button
        type="is-success"
        icon-left="save"
        size="is-small"
        native-type="submit"
        form="form"
        >{{ form.id !== undefined ? `Guardar` : `Agregar`}}</b-button
      >
      <b-button 
        icon-left="times"
        size="is-small"
        @click.prevent="$emit(`cancel`)"
        >Cerrar</b-button>
    </template>
  </modal-base>
</template>
<script>
export default {
  props:{
    value:{
      type: Object,
      default: () => ({}),
    },
    show: {
      type: Boolean,
      default: () => false,
    }
  },
  computed:{
    form :{
      get(){
        return this.value;
      },
      set(e){
        this.$emit("input",e)
      }
    },
    vShow:{
      get(){
        return this.show
      },
      set(e){
        this.$emit("modal", e)
      }
    },
    operadores(){
      return [`=`,`!=`]
    },
    opcionesDate2(){
      return [
        {id: '', value: `Ninguno`},
        {id: 'YYYY-MM-DD', value: `Días`},
        {id: 'YYYY-WW', value: `Semanas`},
        {id: 'YYYY-MM', value: `Meses`},
        {id: 'YYYY', value: `Años`},
      ]
    },
  },
  methods: {
    onMain(){

    },
    async handleSubmitFW(){
      const isValid = await this.$refs.observer2.validate();
      if (!isValid) return;
      // 
      this.$emit(`success`)
      this.vShow = false
    },
  }
}
</script>