<template>
  <div>
    <form-observer ref="observer" id="form1" @submit.prevent="onSubmit">
      <p class="mb-2">ID: <strong>{{form.id.includes(`id`) ? form.id : `id_${form.id}`}}</strong></p>
      <div class="columns is-flex-wrap-wrap">
        <div class="column is-12" v-if="form.where !== undefined">
          <input-base
            rules="required"
            label="Nombre Variable"
            maxlength="150"
            v-model="form.label"
            custom-class="is-uppercase	"
            focus
          />
        </div>
        <div class="column is-12">
          <input-base
            rules="required"
            label="Etiqueta para mostrar"
            maxlength="150"
            v-model="form.newLabel"
          />
        </div>
        <div class="column is-12" v-if="form.where !== undefined">
          <div class="columns is-flex-wrap-wrap">
            <div class="column is-12">
              <input-base
                rules=""
                label="Campo de la tabla"
                maxlength="150"
                v-model="form.labelTable"
              />
            </div>
            <div class="column is-12">
              <select-base
                rules=""
                label="Resumir por"
                v-model="form.aggregateFunc"
                :options="opciones"
                v-show="!form.distinct"
                expanded
              />
            </div>
            <div class="column is-12">
              <switch-base
                rules=""
                label="Campo Distinto"
                v-model="form.distinct"
              >
              Sí
              </switch-base>
            </div>
            <div class="column is-12">
              <h3 class="has-text-weight-bold">
                Donde: 
                <b-button 
                  rounded 
                  outlined 
                  size="is-small" 
                  type="is-info" 
                  icon-left="plus"
                  class="mb-2" @click.prevent="handleAddWhere">Agregar</b-button>
              </h3>
              <table class="table is-bordered is-fullwidth">
                <thead>
                  <tr>
                    <th>Campo</th>
                    <th>Operador</th>
                    <th>Valor</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(row,i) in form.where" :key="i">
                    <td>
                      {{row.label}}
                      {{ row.format ? ` en (${opcionesDate3[`-${row.format}`]})` : ``}}
                    </td>
                    <td class="has-text-centered">{{row.operator}}</td>
                    <td>{{ parseValue(row) }}</td>
                    <td class="has-text-centered">
                      <span 
                        :title="`Editar`"
                        @click.prevent="handleEdit(row,i)" 
                        class="is-clickable has-text-warning">
                        <b-icon icon="edit" />
                      </span>
                      <span 
                        :title="`Eliminar`"
                        @click.prevent="handleDelete(i)" 
                        class="is-clickable has-text-danger">
                        <b-icon icon="trash" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="column is-12" v-else>
          <input-base
            rules="required"
            label="Fórmula"
            maxlength="200"
            v-model="formula_txt"
          />
        </div>
      </div>
      <input type="submit" form="form1" class="is-hidden"/>
    </form-observer>
    <ModalFormWhere 
      v-model="fw"
      :show="show_modal_fw"
      @modal="e => show_modal_fw = e"
      @cancel="show_modal_fw = false"
      @success="handleSaveFW"/>
  </div>
</template>
<script>
import ModalFormWhere from './ModalFormWhere'
export default {
  props:{
    value: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    ModalFormWhere
  },
  data(){
    return {
      fw: {},
      show_modal_fw: false,
    }
  },
  computed:{
    form:{
      get(){
        return this.value
      },
      set(e){
        this.$emit("input",e)
      }
    },
    formula_txt:{
      get(){
        return this.form.formula.join(" ")
      },
      set(e){
        this.form.formula = e.split(" ").map(e => e.trim())
      }
    },
    opciones(){
      return [
        {id: `count`, value: `Recuento`},
        {id: `sum`, value: `Suma`},
        {id: `avg`, value: `Promedio`},
        {id: `min`, value: `Mínimo`},
        {id: `max`, value: `Máximo`},
      ]
    },
    opcionesDate1(){
      return [
        {id: ``, value: `Ninguno`},
        {id: `day`, value: `Día(s)`},
        {id: `week`, value: `Semana(s)`},
        {id: `month`, value: `Mes(es)`},
        {id: `year`, value: `Año(s)`},
      ]
    },
    opcionesDate3(){
      return {
        '': ``,
        'YYYY-MM-DD': `day`,
        '-YYYY-MM-DD': `Días`,
        'YYYY-WW': `week`,
        '-YYYY-WW': `Semanas`,
        'YYYY-MM': `month`,
        '-YYYY-MM': `Meses`,
        'YYYY': `year`,
        '-YYYY': `Años`,
      }
    },
    parseValue(){
      return w => {
        if(w.subtract_format && w.subtract_number){
          const r = this.opcionesDate1.find(r => r.id === w.subtract_format)
          return (w.value === `now()` ? `Fecha actual` : w.value) + ` de hace ${w.subtract_number} ${r.value}`
        }
        return w.value
      }
    }
  },
  methods: {
    async onSubmit(){
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      if(!this.form.label){
        this.$set(this.form, 'label', this.form.newLabel)
      }
      this.$emit('onsuccess')
    },
    handleEdit(e,i){
      this.fw = {...e,...{id:i}}
      this.show_modal_fw = true
    },
    handleDelete(i){
      this.form.where.splice(i, 1)
    },
    handleAddWhere(){
      this.fw = {}
      this.show_modal_fw = true
    },
    handleSaveFW(){
      const {id, ...e} = this.fw
      // 
      if(e.format){
        e.subtract_format = this.opcionesDate3[e.format]
      }
      if(id !== undefined){
        this.form.where.splice(id, 1, e)
      } else {
        this.form.where.push(e)
      }
    }
  }
}
</script>