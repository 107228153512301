<template>
  <div>
    <title-bar :items="[{ title }]" />
    <div class="box">
      <form-observer ref="observer" @submit.prevent="onSubmit">
        <div class="columns">
          <div class="column">
            <TreeVariablesFormulas 
              title="Variables"
              type="v"
              :rows="rows1" />
          </div>
          <div class="column">
            <TreeVariablesFormulas 
              title="Fórmulas"
              type="f"
              :rows="rows2" />
          </div>
        </div>
      </form-observer>
    </div>
  </div>
</template>

<script>
import TreeVariablesFormulas from "../components/TreeVariablesFormulas"
export default {
  components: {
    TreeVariablesFormulas,
  },
  data() {
    return {
      form: {},
      loading: false,
    };
  },
  computed:{
    title(){
      return this.$route.meta.title || "";
    },
    all(){
      return (this.$store.getters["entitie/config"].campos_dinamicos.attrs || [])
        .map(e => e.rows)
        .flat()
        .filter(e => e.type === "custom")
    },
    rows1(){
      return this.all.filter(e => e.where)
    },
    rows2(){
      return this.all.filter(e => e.formula)
    },
  },
};
</script>